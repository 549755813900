@media (min-width: 992px) {
  .modal-lg,
  .modal-xl {
    max-width: 700px !important;
  }
}

.modal-content,
.modal-footer,
.modal-header {
  border: none !important;
}

.modal-body {
  padding: 10px 50px !important;
}

.token.modal-body {
  color: #315e90;
}

.token.modal-body {
  padding-top: 5px;
}

.modal-dialog.modal-warning {
  max-width: 100% !important;
  bottom: 0;
  position: fixed;
  width: 100% !important;
  margin-bottom: 0;
}

button.modal-button.btn.btn-secondary:active,
button.modal-button.btn.btn-secondary,
button.modal-button.btn.btn-secondary:focus {
  background-color: transparent !important;
  width: 100% !important;
  color: #f98724 !important;
  box-shadow: 0 0 0 0;
  border: none !important;
  text-align: left !important;
}

.btn-secondary.focus,
.btn-secondary:focus,
.btn-secondary:not(:disabled):not(.disabled).active:focus,
.btn-secondary:not(:disabled):not(.disabled):active:focus,
.show > .btn-secondary.dropdown-toggle:focus,
button.close.delete:hover {
  box-shadow: 0 0 0 0 !important;
}

button.close.delete {
  margin-top: -12px;
}

button.close.delete:focus {
  outline: none !important;
}

.warning-button {
  color: #d32d2d;
  margin-top: 2px;
}

.modal-dialog.modal-warning.warning-container.modal-lg {
  padding: 0 !important;
}

.warning-container,
.warning-container div.button-container {
  display: flex;
}

div.button-container {
  justify-content: space-around;
}

.warning-container {
  padding: 30px 20px !important;
  justify-content: center;
}

.warning-container div.warning-section {
  margin: 0 40px;
}

.warning-container div.warning-section.text {
  display: flex;
  padding: 4px 0 0 0;
  color: #626262;
  font-weight: bold;
}

.warning-container div.warning-section.text div {
  margin: 0 2px;
  vertical-align: middle;
}

.warning-container div.warning-section.text .icon-container {
  color: #f98724;
  font-size: 22px;
  margin: -4px 10px 0 0;
}

.warning-container div.button-container div {
  margin: 0 8px;
}

button.warning-cancel,
button.warning-accept {
  font-weight: bold;
  font-size: 14px;
  color: white;
  width: 165px;
  border-radius: 6px;
}

button.token-accept {
  font-size: 14px;
  color: white;
  width: 125px;
  border-radius: 6px;
  padding: 4px 0;
}

button.warning-cancel {
  background: #315e90;
  border-color: #315e90;
}

button.warning-accept,
button.token-accept,
button.token-accept:hover,
button.token-accept:active {
  background: #f98724 !important;
  border-color: #f98724 !important;
}

.token .modal-header {
  padding: 0;
}

.modal-header button.close {
  font-size: 45px;
  font-weight: 200;
  color: #e67b2e;
  opacity: inherit;
}

.modal-header button.close:focus {
  outline: 0 !important;
}

.token.modal-body .title-text {
  font-size: 20px;
}

.token.modal-body .body-text {
  font-size: 14px;
  line-height: 18px;
  padding: 25px 0 10px 20px;
}

.token.modal-body .body {
  padding: 0 30px;
}

.token.modal-body .modal-footer {
  padding-right: 8%;
}

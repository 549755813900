.actions-container {
  display: flex;
  margin: 0 15px;
  justify-content: space-between;
}

.estado-list .actions-container {
  margin: 0 15%;
}

.tooltip {
  font-size: 10px !important;
}

.actions-container div.button-container {
  margin: 0 10px;
}

.actions-container div.button-container.detail {
  margin-top: 2px;
}

.actions-container div.button-container.lock {
  margin-top: -1px;
}

.table-cell,
.table-header {
  color: #315e90;
}

.table-cell.actions {
  margin: 0 10px;
}

.table-link {
  color: #f98724;
}

button.table-link {
  background: none;
  color: #f98724 !important;
  border: none;
  padding: 0 !important;
  font: inherit;
  font-size: 15px;
  cursor: pointer;
}

button.table-link:hover,
button.table-link:active {
  background: none !important;
}

button.table-link:focus {
  outline: none !important;
  box-shadow: 0 0 0 0 !important;
}

button.table-link.disabled {
  color: #cccccc !important;
  pointer-events: none;
  cursor: default;
}

a.table-link {
  color: #f98724 !important;
  cursor: pointer;
}

a.table-link.disabled {
  color: #cccccc !important;
}

a.table-link.policy.disabled {
  pointer-events: none;
  cursor: default;
  color: #cccccc !important;
}

.table-header {
  font-weight: 600;
  padding: 5px 0;
  font-size: 14px;
}

.table-cell {
  font-size: 14px;
  padding: 3px 8px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.table-cell.wrap {
  white-space: normal;
  word-break: break-word;
}

.movimientos-table .rt-th,
.movimientos-table .rt-td {
  padding-left: 20px !important;
}

/* ReactTable */

.rt-thead.-filters {
  background: #efefef;
}

.ReactTable {
  border: 1px solid #cccccc;
}

.ReactTable .rt-thead.-filters .rt-th {
  border: none;
}

.ReactTable .-sort-desc {
  box-shadow: none !important;
}

.ReactTable .rt-th.-cursor-pointer:before {
  font-family: 'Font Awesome\ 5 Free';
  content: '\f0dc';
  color: #315e90;
  float: right;
  font-weight: 900;
}

.ReactTable .-sort-asc {
  box-shadow: none !important;
}

.ReactTable .-sort-asc:before,
.ReactTable .rt-th.-cursor-pointer:before {
  font-family: 'Font Awesome\ 5 Free';
  content: '\f0dc';
  color: #315e90;
  float: right;
  margin-top: 3px;
  margin-right: 1px;
  font-size: 14px;
  font-weight: 900;
}

.ReactTable .rt-thead.-filters input,
.ReactTable .rt-thead.-filters select {
  padding: 8px;
}

.ReactTable .rt-th {
  overflow: visible;
}

.ReactTable .rt-thead .rt-tr {
  text-align: left;
}

.filter-select {
  white-space: normal;
}

div.rt-thead.-filters {
  font-size: 14px;
}

.detail-table div {
  overflow-x: hidden !important;
}

/*filters*/
.rt-th input {
  font-size: 14px !important;
}

.table-link.icon.disabled {
  background: transparent;
}

.table-link.icon.disabled svg {
  color: #cccccc !important;
}

.reactstrap-table-link {
  color: #f98724 !important;
  padding: 0 !important;
}

/* FixedTable */
.fixed-table .rt-th.rthfc-th-fixed.rthfc-th-fixed-left,
.fixed-table .rt-tr .rt-th {
  padding: 10px 5px 10px 20px;
  background-color: #efefef;
}

.fixed-table .table-cell {
  padding: 7px 12px;
  text-overflow: ellipsis;
}

.fixed-table .rt-th.fixed {
  border-right: 1px solid #cccccc !important;
}

.fixed-table .rt-th {
  background-color: #efefef;
}

.fixed-table .rt-th.principal.rthfc-th-fixed.rthfc-th-fixed-left.rthfc-th-fixed-left-last {
  background-color: #efefef;
}

.fixed-table .rt-th.principal {
  background-color: #efefef !important;
  display: none;
}

.fixed-table.ReactTable .rt-thead .rt-th,
.fixed-table.ReactTable .rt-td,
.rt-th.principal.rthfc-th-fixed.rthfc-th-fixed-left.rthfc-th-fixed-left-last,
.fixed-table.ReactTable .rt-thead .rt-td {
  padding: 0;
}

.fixed-table .rt-thead.-header {
  top: 0 !important;
}

/* Iconos para correcto */

.table-valid-icon {
  font-weight: 400;
  color: var(--green);
  text-decoration: none;
}

.table-invalid-icon {
  font-weight: 400;
  color: var(--red);
  text-decoration: none;
}

* {
  font-family: 'Source Sans Pro', sans-serif;
}

html {
  height: 100%;
  box-sizing: border-box;
}

*,
*:before,
*:after {
  box-sizing: inherit;
}

body {
  position: relative;
  margin: 0;
  padding-bottom: 6rem;
  min-height: 100%;
  color: #315e90 !important;
}

.app-container {
  margin-top: 5px;
}

/*PageContainer*/
a.regular-link,
.date {
  color: #315e90;
  font-size: 14px;
}

.filter-container {
  font-size: 14px;
}

.filter-container,
.table-container,
.resumen-container {
  padding: 0 15px;
}

.btn-orsan {
  background: #f98724 !important;
  background: var(--orange) !important;
  border: #f98724 !important;
  border: var(--orange) !important;
  color: #fff !important;
  font-size: 14px !important;
  font-weight: 400;
}

.fecha-arbitraria div.group {
  margin-left: 15px;
}

.group.disabled input {
  background: #f2f2f2;
}

.fecha-arbitraria div.title {
  margin-top: 10px;
}

#reporte {
  width: 240px;
}

.fecha-arbitraria .datetime-icon {
  margin-left: -14%;
}

.button-container.group .btn-orsan {
  margin-top: 4px;
  width: 100px;
}

/*EstadoList*/
.estado-filter {
  margin: 0 10px 20px 0;
  padding-right: 2% !important;
}

.fecha-arbitraria .rdtPicker {
  width: 130%;
}

.filter-container div.text-right div.button-container button {
  width: 200px !important;
}

.filter-container div.text-right div.button-container button span {
  margin-left: 5px;
}

.filter-container div.text-right div.button-container button {
  margin-left: 12px;
}

/*EstadoDetail*/
.resumen-container {
  margin-bottom: 10px;
}

.resumen-wrapper {
  border: 1px solid #cccccc;
  border-radius: 5px;
  padding: 15px 12px;
}

.resumen-wrapper.mensuales {
  margin: 10px 0;
  padding: 15px 30px;
  display: flex;
  /*justify-content: space-between;*/
}

.resumen-wrapper.mensuales .resumen-row {
  display: flex;
  /*justify-content: space-between;*/
}

.resumen-wrapper.mensuales .resumen-column {
  margin-right: 8%;
}

.resumen-wrapper .resumen-row {
  margin: 5px 0;
}

.resumen-wrapper .resumen-flex {
  display: flex;
  justify-content: space-between;
}

.resumen-wrapper .label,
.resumen-wrapper .value {
  font-size: 14px;
}

.resumen-wrapper .label {
  font-weight: 600;
}

.resumen-wrapper.mensuales .resumen-column.principal {
  min-width: 280px;
}

.resumen-wrapper.mensuales .resumen-column.principal .label {
  min-width: 110px;
}

.resumen-wrapper.mensuales .resumen-column .label {
  margin-right: 11px;
}

.resumen-wrapper .alert {
  width: 65px;
  font-size: 11px;
}

.resumen-section {
  border-left: 1px solid #cccccc;
}

#movimientos {
  margin: 35px 0;
}

#movimientos .title-container {
  margin-top: 2px;
}

#movimientos span.text {
  padding-left: 5px;
}

#movimientos .button-container.contable {
  padding-right: 10px;
}

#movimientos .table-container {
  margin: 10px 0;
}

/*Footer*/
.footer {
  position: absolute;
  right: 0;
  left: 0;
  bottom: 0;
  padding: 30px 45px;
  width: 100%;
  background-color: #292b33;
  letter-spacing: -0.22px;
  line-height: 18px;
  color: #cccccc;
  font-size: 14px;
  text-align: center;
}

/* Forms */

.form-section label,
.form-section .form-title,
.form-section .fa-chevron-right,
.form-section .fa-check-circle,
.form-values,
.small-form-value {
  color: #315e90;
}

.form-section .fa-times-circle {
  color: #f98724;
}

.form-title {
  font-size: 20px;
  font-weight: 400;
  letter-spacing: -0.27px;
  line-height: 25px;
}

.form-values {
  margin-top: 5px;
  font-weight: normal;
}

.small-form-value {
  margin-top: 5px;
  font-weight: normal;
  font-size: 14px;
  color: #315e90;
}

.small-text {
  font-size: 14px;
  color: #315e90;
}

.pre-wrap-white-space {
  white-space: pre-wrap;
}

.no-margin-top {
  margin-top: 0;
}

.download,
button.download,
button.nav-link,
button.download:active {
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
}

button.nav-link {
  margin: 8px 5px;
}

.download,
button.download,
button.download:active {
  color: #f98724 !important;
}

.download:focus {
  outline: none;
}

.form-separator {
  height: 1px;
  color: #f98724;
  background-color: #f98724;
  border: none;
  margin: 0 0 15px 0;
}

.form-section label {
  margin-bottom: 8px;
  font-size: 14px;
  font-weight: 600;
  letter-spacing: -0.19px;
  line-height: 18px;
}

.custom-policy-select {
  font-size: 14px;
  color: #626262;
}

#intermediario,
#asegurado,
#afianzado {
  min-width: 300px;
}

.form-control {
  font-size: 14px !important;
  color: #626262;
}

div.size-warning {
  color: #315e90;
  font-size: 14px;
  margin-top: 10px;
}

.has-error > .custom-policy-select > div:first-child {
  border-color: #dc3545;
}

.env-message {
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 1000;
  padding: .75rem 1.25rem;
  /* default staging values */
  border: 2px dashed #F59A11;
  background-color: #F4B454;
  color: white;
}

.estado-container {
  margin: 0 15px;
}

.alert {
  margin-bottom: 0 !important;
  padding: 0 !important;
  color: white !important;
}

.alert-open {
  background-color: #39ca74 !important;
  border-color: #39ca74 !important;
}

.alert-closed {
  background-color: #626262 !important;
  border-color: #626262 !important;
}

.alert-course {
  background-color: #315e90 !important;
  border-color: #315e90 !important;
}

.alert-pending {
  background-color: #cccccc !important;
  border-color: #cccccc !important;
}

/* Navbar */
.navbar-content {
  display: grid;
  grid-template-columns: 200px 1fr;
  height: 100%;
  grid-column: 2;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 5px;
}

.navbar-header {
  font-weight: 600;
  min-width: 170px;
}

.nav-logo-container {
  align-items: center;
  display: flex;
  padding: 0 0 0 15px;
}

.nav-logo {
  width: 100%;
}

.nav-link {
  cursor: pointer;
}

.nav-links-group {
  list-style-type: none;
  margin: 0;
}

.nav-link-element,
.nav-link,
.dropdown-item {
  color: #315e90 !important;
}

.nav-link-element {
  display: inline-block;
}

.nav-link-element.dropdown.nav-item {
  padding-top: 10px;
}

.nav-link-element.nav-link.username {
  padding: 15px 20px 0 0;
}

.rt-thead.-filters {
  padding-left: 10px;
}

.login-column.logo {
  text-align: center;
  clear: right;
}

.dropdown-menu .dropdown-item {
  padding: 0.25rem 0;
}

.dropdown-menu {
  padding: 0 !important;
}

.navbar-header.dropdown-toggle::after {
  float: right;
  margin-top: 0.5625em;
}

button.nav-link {
  background: none !important;
  border: none;
  padding: 0 !important;
  cursor: pointer;
}

button.nav-link {
  margin: 8px 5px;
}
.breadcrumb {
  background: transparent !important;
  font-size: 22px;
}

.breadcrumb-item {
  font-weight: bold;
}

.breadcrumb-item.active,
.breadcrumb-item + .breadcrumb-item::before {
  color: #315e90 !important;
}

.principal.breadcrumb-item,
.principal.breadcrumb-item a {
  color: var(--orange) !important;
}

.breadcrumb-item.principal a {
  cursor: pointer;
}
